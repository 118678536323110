import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import {HomeComponent} from './components/pages/home/home.component';
import {FuncionalComponent} from './components/pages/funcional/funcional.component';
import {ContatoComponent} from './components/pages/contato/contato.component';
import {ServicosComponent} from './components/pages/servicos/servicos.component';


const routes: Routes = [
  { path: '',             component: HomeComponent },
  { path: 'funcional',    component: FuncionalComponent },
  { path: 'contato',      component: ContatoComponent },
  { path: 'servicos',     component: ServicosComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
