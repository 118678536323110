import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HeaderComponent } from './components/shared/header/header.component';
import { FooterComponent } from './components/shared/footer/footer.component';
import { HomeComponent } from './components/pages/home/home.component';
import { FuncionalComponent } from './components/pages/funcional/funcional.component';
import { ServicosComponent } from './components/pages/servicos/servicos.component';
import { ClippingComponent } from './components/pages/clipping/clipping.component';
import { ContatoComponent } from './components/pages/contato/contato.component';
import { NavbarComponent } from './components/shared/navbar/navbar.component';
import {NgxGoogleMapHelperModule} from 'ngx-google-map-helper';
import { HomeNavbarComponent } from './components/shared/home-navbar/home-navbar.component';
import { GlobalsService } from './common/services/globals.service';

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    FooterComponent,
    HomeComponent,
    FuncionalComponent,
    ServicosComponent,
    ClippingComponent,
    ContatoComponent,
    NavbarComponent,
    HomeNavbarComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    NgxGoogleMapHelperModule.forRoot({apiKey: 'AIzaSyBJQplV7igEtRKTuy71Z_fmXgyukikmDg4'})
  ],
  providers: [GlobalsService, HeaderComponent],
  bootstrap: [AppComponent]
})
export class AppModule { }
