import { Injectable, EventEmitter } from '@angular/core';
import {Router} from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class GlobalsService {

  public isHome = false;

  headerReload = new EventEmitter();

  constructor(private router: Router ) {
    this.DefinePageProperties();
  }

  public ReloadHeader() {
    this.DefinePageProperties();
    this.headerReload.emit();
  }

  private DefinePageProperties() {
    this.isHome = this.router.url  === '/';
  }

}
