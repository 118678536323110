import { Component } from '@angular/core';
import {HeaderComponent} from '../header/header.component';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.css']
})
export class NavbarComponent {

  constructor(private header: HeaderComponent) { }

  setSubtitle(subtitle, isHome) {
    this.header.setPageSubtitle(subtitle, isHome);
  }

}
