import { Component } from '@angular/core';
import {HeaderComponent} from '../../shared/header/header.component';
import {GlobalsService} from '../../../common/services/globals.service';

@Component({
  selector: 'app-funcional',
  templateUrl: './funcional.component.html',
  styleUrls: ['./funcional.component.css']
})

export class FuncionalComponent {

  constructor(private globalsService: GlobalsService) {
    this.globalsService.ReloadHeader();
  }

}
