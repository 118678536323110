import {Component, OnInit} from '@angular/core';
import {GlobalsService} from '../../../common/services/globals.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})

export class HeaderComponent implements OnInit {

  public pageSubtitle = 'Treinamento Funcional';
  constructor(public globalsService: GlobalsService) { }

  ngOnInit(): void {}

  public setPageSubtitle(subtitle: string, isHome: boolean) {
    this.pageSubtitle = subtitle;
    this.globalsService.isHome = isHome;
  }
}
