import { Component, OnInit } from '@angular/core';
import {GlobalsService} from '../../../common/services/globals.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent {

  constructor(private globalsService: GlobalsService) {
    this.globalsService.ReloadHeader();
  }

}
